<div class="wage" *ngIf="show">
  <div class="projectCheck" *ngIf="!  succussful">
    <div class="check">
      <div class="header">
        <div>Copy Template to Project</div>
        <div (click)="show = false" class="close">
          X
        </div>
      </div>
<!--      <hr/>-->
      <div class="copy_content">
        <div class="select">
          <div>Select Project:</div>
           <select  [(ngModel)]="projectSelect">
              <option *ngFor="let item of projects" [value]="item.id">
              {{item.name}}
              </option>
            </select>
        </div>
        <div class="FormButton">
          <div class="copyButton" (click)="copyModuleItem()">
            Copy
          </div>
          <div class="closeButton" (click)="show = false">
            Close
          </div>
        </div>
      </div>

  </div>
  <div class="succussful" *ngIf="succussful">
    <h2>template copied to project</h2>
    <div class="pic">
      <div class="icon icon-checkmark iconsuccussful"></div>
    </div>
  </div>
</div>

