<div class="mask" (click)="closeClick()">
  <div class="frame" (click)="$event.stopPropagation()">
    <div class="title">
      <div style="height: 40px;line-height: 40px;">Site Diary Approval</div>
      <div class="close layui-layer-ico" (click)="closeClick()"></div>
    </div>
    <div class="signer">Signer:{{itemSiteDiary.next_user}}</div>
    <div class="content">
      <div class="content-title">
        <div>
        <span>{{itemSiteDiary.daily_date}} </span>
        <span *ngFor="let item of activity_teams;let ind=index" [ngClass]="{selectTab:clickTeamIndex==ind?true:false,rejectState:adoptData.activitieTeams[ind].checkFlag?true:false}"  class="spanTab" (click)="tabteamClick(ind)">
         {{'team'+item.team_id}}
        </span>
      </div>
      <span>Total:{{activities.length}}</span>
    </div>
      <div class="content-title">
          <div>
          <!-- <span>{{itemSiteDiary.daily_date}} </span> -->
          <span *ngFor="let item of activities;let ind=index" [ngClass]="{selectTab:clickIndex==ind?true:false,rejectState:adoptData.activitieTeams[clickTeamIndex].activities[ind].checkFlag?true:false}"  class="spanTab" (click)="tabClick(ind)">
           {{item.section_name}}
          </span>
        </div>
        <!-- <span>Total:{{activities.length}}</span> -->
      </div>
      <div class="portion" *ngFor="let itemlist of activities; let j = index">
        <ng-container *ngFor="let item of itemlist.list; let i = index">
          <div class="portion-item" *ngIf="j==clickIndex">
            <div class="portion-item-title">{{item.protion_name}} / {{item.location}}</div>
            <div class="portion-item-input">
              <div class="portion-item-input-title">Activity：</div>
              <div class="portion-item-input-text">{{item.activity}}</div>
            </div>
            <div class="portion-item-input">
              <div class="portion-item-input-title">Period：</div>
              <div class="portion-item-input-text">{{item.period_start}}-{{item.period_end}}</div>
            </div>
            <div class="portion-item-input">
              <div class="portion-item-input-title">Remark：</div>
              <div class="portion-item-input-text" >{{item.remark}}</div>
            </div>
            <hr>
            <div class="portion-item-Labours" style="padding-bottom: 20px;">
              <div class="portion-item-Labours-title">Labours / People：{{item.labur_number}}</div>
              <div class="portion-item-Labours-leftright">
                <div class="portion-item-Labours-left" style="font-weight: bold;border-right: none;border-top: 1px solid #bbb;">Labour</div>
                <div class="portion-item-Labours-right" style="font-weight: bold;border-top: 1px solid #bbb;">Number</div>
                </div>
                <ng-container *ngFor="let itemLabours of item.labours">
                  <div class="portion-item-Labours-leftright">
                    <div class="portion-item-Labours-left" style="border-right: 0;">{{itemLabours.labour_name}}</div>
                    <div class="portion-item-Labours-right">{{itemLabours.number}}</div>
                  </div>
                </ng-container>
              </div>
              <hr>
              <div class="portion-item-Labours" style="margin-top:20px;padding-bottom: 20px;">
                <div class="portion-item-Labours-title">Plant / Equipment：{{numberData(item.plants,'working')}} / {{numberData(item.plants,'ldle')}}</div>
                <div class="portion-item-Labours-leftright">
                  <div class="portion-item-Plant" style="border-top: 1px solid #bbb;border-right: 0;">Item</div>
                  <div class="portion-item-Plant" style="border-top: 1px solid #bbb;border-right: 0;">ID</div>
                  <div class="portion-item-Plant" style="border-top: 1px solid #bbb;border-right: 0;">Working</div>
                  <div class="portion-item-Plant" style="border-top: 1px solid #bbb;">Idle</div>
                </div>
                <ng-container *ngFor="let itemPlants of item.plants">
                  <div class="portion-item-Labours-leftright">
                    <div class="portion-item-Plant" style="border-right: 0;">{{itemPlants.equipment}}</div>
                    <div class="portion-item-Plant" style="border-right: 0;">{{itemPlants.id}}</div>
                    <div class="portion-item-Plant" style="border-right: 0;">{{itemPlants.working}}</div>
                    <div class="portion-item-Plant">{{itemPlants.ldle}}</div>
                  </div>
                </ng-container>
            </div>
            <div class="approval">
              <div class="approval-title">Approval：</div>
              <div class="approval-button">
                <button [disabled]="!itemSiteDiary.user_signed_power" [ngStyle]="{'cursor': !itemSiteDiary.user_signed_power? 'not-allowed':''}" [ngClass]="{'clickbutton': adoptData.activitieTeams[clickTeamIndex].activities[j][i].state}" (click)="approvalClick(j,i,true)">Yes</button>
                <button [disabled]="!itemSiteDiary.user_signed_power" [ngStyle]="{'cursor': !itemSiteDiary.user_signed_power? 'not-allowed':''}" [ngClass]="{'clickbuttonNO': !adoptData.activitieTeams[clickTeamIndex].activities[j][i].state}" (click)="approvalClick(j,i,false)">No</button>
              </div>
            </div>
            <div class="remark" >
              <div class="remark-title">Remark：</div>
              <textarea class="remark-input" [disabled]="!itemSiteDiary.user_signed_power" [(ngModel)]="adoptData.activitieTeams[clickTeamIndex].activities[j][i].reason"></textarea>
            </div>
          </div>
        </ng-container>

      </div>
      <div class="bottonBt">
        <button style="background: #009ac9; color: #ffffff; border: 1px solid #009ac9" (click)="sendClick()" *ngIf="!displayButton() && itemSiteDiary.user_signed_power">Send</button>
        <button style="background: #009ac9; color: #ffffff; border: 1px solid #009ac9" (click)="approveClick()" *ngIf="displayButton() && itemSiteDiary.user_signed_power">Approve & Signature</button>
        <button style="background: #ffffff; color: #000000; border: 1px solid #000000" (click)="closeClick()">Cancel</button>
      </div>
    </div>
  </div>
</div>

<wp-signature *ngIf="dipslesignature" (closeView)='closeView($event)'></wp-signature>
<div class="loading-indicator loadingposition" *ngIf="showloading">
  <div class="block-1"></div>
  <div class="block-2"></div>
  <div class="block-3"></div>
  <div class="block-4"></div>
  <div class="block-5"></div>
</div>