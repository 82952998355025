<div class="SectionItem__loading loadingDiv" *ngIf="showLoading">
  <div class="loading-indicator -compact">
    <div class="block-1"></div>
    <div class="block-2"></div>
    <div class="block-3"></div>
    <div class="block-4"></div>
    <div class="block-5"></div>
  </div>
</div>
<div class="titleclss" >
  <div class="check-title">
    <div class="toolbar-container">
      <div class="toolbar">
          <div class="title-container" style="display: flex;">
            <h2  class="titleName">Site Diary</h2>
          </div>
      </div>
      <div>
        <button style="margin-right: 15px;width: 180px;" (click)="export_monthly()">Export Excel</button>
        <button style="margin-right: 15px;width: 180px;" (click)="openDownLoadDialog()"  [ngClass]="{'canNoClick':canNoClick }">Download Excel</button>
        <button *ngIf="ConsultantType" (click)="addSiteDiaryList(null, 'create')" class="icon-add" [ngClass]="{'canNoClick':canNoClick }">Add</button>
      </div>
    </div>
    </div>
    <div class="filter">
      <!--<a [ngClass]="{'filterSelect': action == 'my_action'}" (click)="getList('my_action')">My Actions</a>
      <span>|</span>-->
      <a [ngClass]="{'filterSelect': action == 'draft'}" (click)="getList('draft')">Draft</a>
      <span>|</span>
      <a [ngClass]="{'filterSelect': action == 'siow_signed'}" (click)="getList('siow_signed')">Siow_signed</a>
      <span>|</span>
      <a [ngClass]="{'filterSelect': action == 'contractor_signed'}" (click)="getList('contractor_signed')">Contractor_signed</a>
      <span>|</span>
      <a [ngClass]="{'filterSelect': action == 'supervisor_signed'}" (click)="getList('supervisor_signed')">Supervisor_signed</a>
      <span>|</span>
      <a [ngClass]="{'filterSelect': action == 'recheck'}" (click)="getList('recheck')">Recheck</a>
      <span>|</span>
      <a [ngClass]="{'filterSelect': action == 'all'}" (click)="getList('all')">All</a>
    </div>
    <div class="work-packages-split-view--tabletimeline-content">
      <div class="work-packages-tabletimeline--table-side work-package-table--container __hidden_overflow_container">

        <table style=" margin: 0 auto" class="keyboard-accessible-list generic-table work-package-table">
          <thead class="-sticky">
            <tr style="border-bottom: 1px solid #E7E7E7;"  class="listTitle">
              <th>
                Date
              </th>
              <th>Status</th>
              <th>Created by</th>
              <th>Created at</th>
              <th>Handler</th>
              <th>Staff</th>
              <th>Labour/People</th>
              <th>Plant/Equipment</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody class="results-tbody work-package--results-tbody" *ngFor="let item of SiteDiaryList">
            <tr>

              <td>{{item.daily_date}}</td>
              <td>{{getstateName(item.state)}}</td>
              <td class="defaultWidth" >{{item.user_name}}</td>
              <td class="defaultWidth" >{{item.created_at}}</td>
              <td class="handlerwidth">{{item.pm_user.name + ', ' + item.contractor_signer.name + ', ' + item.supervisor_signer.name}}</td>
              <td class="defaultWidth">{{item.site_staff}}.0</td>
              <td class="defaultWidth">{{item.labour_items}}.0</td>
              <td>{{item.plant_working}}.0/{{item.plant_ldle}}.0</td>
              <td class="tableButtonCase" width="410">
                <div class="tablebutton">
                <div (click)="listItemClick('edit',item.id)" style="background-color: #2f8bc7; min-width: 50px;">{{item.state.includes('signed')? 'View': 'Edit'}}</div>
                <div (click)="listItemClick('view',item)" [style.visibility]="item.state == 'supervisor_signed'? 'hidden': 'visible'" style="background-color:#4eb862">Assign Next</div>
                <div (click)="listItemClick('delete',item)" style="background-color: #e15353;"
                [style.visibility]="item.state == 'draft'? 'visible': 'hidden'" >Delete</div>
                <!-- <div (click)="listItemClick('pdf',item.id)">PDF</div> -->
                <div style="background-color: #45c0dc;position: relative;"><a (click)="showPdfList(item.id)" style="color: #fff">PDF <i class="layui-icon layui-icon-down layui-font-12"></i></a>
                  <div class="Pdflist" *ngIf="isShowPdfIndex == item.id">
                    <div class="pdfItme" (click)="getPdfHref(item.id,  item.daily_date, 'pdf1')">Unsigned</div>
                    <div class="pdfItme" (click)="getPdfHref(item.id,  item.daily_date , 'pdf2')">Signed</div>
                  </div>
                </div>
                <div (click)="listItemClick('copy' ,item.id)" style="border: #448efc 2px solid; color: #448efc;">copy</div>
                <div (click)="changStatus(item.id)" style="background-color: #dcbe45;min-width: 50px; line-height: 28px;" *ngIf="isUserAdmin">
                  Reset
                </div>
                <div (click)="changeTime(item.id)" style="background-color: #6c45dc;min-width: 50px; line-height: 28px;" *ngIf="isUserAdmin">
                  Sign Time
                </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
</div>
<div id="pagging"></div>
<!-- <div class="pagging">
  <div class="pageLeft">
    Showing {{page*page_size-page_size}} to {{page*page_size}} of {{total_num}} rows
    <select [(ngModel)]="page_size" class="leftSelect" (change)="selectEvent($event.target.value)">
      <option value="5">5</option>
      <option value="10">10</option>
      <option value="15">15</option>
      <option value="20">20</option>
    </select>
    rows per page
  </div>
  <div class="pageRight">
   <ul >
     <li (click)="pageEvent(page-1)"><</li>
     <li *ngFor="let item of numarr;let i =index" [ngClass]="{'selectLi': page==i+1?true:false}" (click)="pageEvent(i+1)">{{item}}</li>
     <li (click)="pageEvent(page+1)">></li>
   </ul>
  </div>
</div> -->


<!-- modulelist 分页 -->
<!-- <ng-container >
  <div class="pagination" style="position: absolute; bottom: 10px;">
   <ul class="pagination--items">
     <li class="pagination--item" *ngIf="modulepage-1 != 0"><a href="" (click)='modulego("prev")' id="pagination--prev-link" rel="prev start" role="link" aria-label="Back to the previous page">&lt;</a></li>
     <li *ngFor="let item of modulesize;let i = index" class="pagination--item pagination-number" ng-reflect-klass="pagination--item pagination-nu" ng-reflect-ng-class="[object Object]">
       <a [ngClass]="{peracss:modulepage==i+1}" (click)='modulelistpage(i+1)' href="" rel="next" role="link">{{i+1}}</a></li>
      <li class="pagination--item" *ngIf="modulepage != modulesize.length"><a (click)='modulego("next")' class="pagination--next-link" href="" id="pagination--next-link" rel="next" role="link" aria-label="Forward to the next page">&gt;</a></li>
    </ul>
  </div>
 </ng-container> -->

<!-- 添加弹窗 -->
<SiteDiary-List-Popup *ngIf="topopup" type='create' (close)="addSiteDiaryList($event, 'edit')"></SiteDiary-List-Popup>
<SiteDiary-List-Popup *ngIf="toCopyPopup" type='copy' [id]='copyId' (close)="addSiteDiaryList($event, 'copy')"></SiteDiary-List-Popup>
<SiteDiary-List-Approval *ngIf="approval" [itemSiteDiary]="itemSiteDiary" (close)="approvalClose($event)"></SiteDiary-List-Approval>