<div class="mask" (click)=" closeClick()">
  <div class="frame" (click)="$event.stopPropagation();" >
    <div class="loading-indicator loadingposition" *ngIf="showloading">
      <div class="block-1"></div>
      <div class="block-2"></div>
      <div class="block-3"></div>
      <div class="block-4"></div>
      <div class="block-5"></div>
    </div>
    <div class="title">
      <div style="height: 40px;line-height: 40px;">{{type === 'create'? 'New': 'Copy' }} Site Diary</div>
      <div class="close layui-layer-ico" (click)="closeClick()"></div>
    </div>
    <div class="content">
      <div class="time">
        <span style="margin-top:15px">Select Date：</span>
        <div  class="seachDate-dateItem" id="dateNow"  (click)="dateNowChange()">{{dateNow}}</div>
      </div>
      <div class="file" *ngIf="flag">
        <h3>File Ref No</h3>
        <p><b>Prefix:</b> <span>SiteDiary</span></p>
        <p><b>ID:</b> <span>-</span></p>
        <p><b>Type:</b> <select [(ngModel)]="saveSelect">
          <option [value]="i" *ngFor="let item of selectValue let i = index">{{item.value.value}}</option>
        </select></p>
        <p><b>Suffix:</b> <span>-</span></p>
      </div>
      <button class="addOK icon-add" (click)="addSiteDiary()">{{type === 'create'? 'Add': 'Copy'}}</button>
    </div>
    </div>
</div>