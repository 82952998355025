import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { I18nService } from 'core-app/modules/common/i18n/i18n.service';
import { SiteDiaryService } from '../services/SiteDiary.service';
import {ExpressionService} from "../../../../../common/expression.service";



declare let layui: any

@Component({
  selector: 'SiteDiary-List',
  templateUrl: './SiteDiary-List.component.html',
  styleUrls: ['./SiteDiary-List.component.sass']
})
export class SiteDiaryListComponent implements OnInit {
  @Output() listItemButton = new EventEmitter();
  @Input() public displayTemplate:any
  @Input() public currentPage:any
  @Input() public oldAction:any

  public SiteDiaryList = []

  public itemSiteDiary:any = null

  public topopup = false
  public toCopyPopup = false // copy弹窗开关
  public copyId = null
  public approval = false
  public showLoading:boolean = false
  public modulesize:any = [1,2,3,4,5]   //module页码
  public modulelist:any
  public modulepage=1

  public page=1
  public page_size=20
  public total_num:any
  public numarr:any = []
  public action:any = 'all'
  public canNoClick:any
  public isShowPdfIndex:any = null
  public ConsultantType:boolean = false
  public first:boolean = true
  public isUserAdmin = false
  public project_id = ''
  constructor(
    public i18n: I18nService,
    public service: SiteDiaryService,
    public http: HttpClient
  ) { }

  ngOnInit() {
    const el =  document.querySelector('meta[name=current_project]')
    // @ts-ignore
    this.project_id = el.dataset.projectIdentifier
    this.getSiteDiaryList()
    this.getConsultantList()
    const currentUser: any = document.querySelector('meta[name=current_user]');
    // @ts-ignore
    this.service.getUserList(el.dataset.projectId,  null).subscribe((res: any) => {
      res._embedded.elements.forEach((resitem:any)=>{
        // @ts-ignore
        if(resitem.id == currentUser.dataset.id){
          if(resitem.admin) {
            sessionStorage.setItem('isUserAdmin','true')
            this.isUserAdmin = true
          }else{
            this.isUserAdmin = false
            sessionStorage.setItem('isUserAdmin','false')
          }
        }
      })
    })
  }
  /**
   * @author shane
   * @description: 更改簽名時間
   * @date 2023--12--08 18 03 25
  */
  changeTime(id:number){
    let self = this
    let newsignDate = ''
    layui.use(['jquery','layer','laydate', ], function() {
      var layer = layui.layer,$ = layui.$,
        laydate =  layui.laydate;
      layer.open({
        content:`<div class="layui-input-inline">
                    <input type="text" class="layui-input" id="signTime" placeholder="yyyy-MM-dd HH:mm:ss">
                  </div>`,
        // area: ['400px', '400px'],
        title:'sign Time',
        btn:['Confirm'],
        yes: function(index:any, layero:any){
          fetch(`/api/v3/projects/${self.project_id}/site_diary/update_pm`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              'X-Requested-With': 'XMLHttpRequest',
              'X-Authentication-Scheme':'Session',
              'X-CSRF-TOKEN':jQuery("meta[name=csrf-token]").attr("content")||'',
            },
            body: JSON.stringify({
              site_diary_id: id,
              pm_signed_date: newsignDate
            })
          })
            .then(response => response.json())
            .then(data => {
              self.getSiteDiaryList()
              layer.close(index); //如果设定了yes回调，需进行手工关闭
              layer.msg('Update Success', {icon: 1});
            })
            .catch(error => console.error(error))
        }
      });
      laydate.render({
        elem: '#signTime',
        type: 'datetime'
        ,isInitValue: true
        ,lang: 'en'
        ,done: function(value:any, date:any, endDate:any){
          newsignDate = value
          console.log(value,date,endDate); //得到日期生成的值，如：2017-08-18
        }
      });
    })
  }
  /**
   * @author shane
   * @description: 修改狀態
   * @date 2023--12--07 15 54 27
   */
  changStatus(item:any){
    console.log(item,'ida')
    let self = this
    layui.use(['layer'], function() {
      let layer = layui.layer
      layer.confirm('Confirm to Change the Status？', {
        title:'Alert',
        btn: ['Yes','Cancel'] //按钮
      }, function(){
        fetch(`/api/v3/projects/${self.project_id}/site_diary/update_pm`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'X-Authentication-Scheme':'Session',
            'X-CSRF-TOKEN':jQuery("meta[name=csrf-token]").attr("content")||'',
          },
          body: JSON.stringify({
            state: 'draft',
            site_diary_id: item
          })
        })
          .then(response => response.json())
          .then(data => {
            self.getSiteDiaryList()
            layer.msg('Update Success', {icon: 1});
          })
          .catch(error => console.error(error))
        console.log('確認')
      }, function(){
        console.log('取消')
      });
    })
  }
  /**
   * @author shane
   * @description: 导出每月统计
   * @date 2023--12--07 16 12 44
   */
  export_monthly(){
    let self = this
    let exportDate = ''
    layui.use(['jquery','layer','laydate', ], function() {
      var layer = layui.layer,$ = layui.$,
        laydate =  layui.laydate;
      layer.open({
        content:`<div class="layui-input-inline">
                    <input type="text" class="layui-input" id="signTime" placeholder="Select Export Start Date">
                  </div>`,
        // area: ['400px', '400px'],
        title:'Export Excel',
        btn:['Confirm'],
        yes: function(index:any, layero:any){
          layer.close(index);
          window.open(`/projects/${self.project_id}/site_diary_export_excel_month?month=${exportDate}`)
        }
      });
      laydate.render({
        elem: '#signTime',
        type: 'date'
        ,isInitValue: true
        ,lang: 'en'
        ,done: function(value:any, date:any, endDate:any){
          exportDate = value
          console.log(value,date,endDate); //得到日期生成的值，如：2017-08-18
        }
      });
    })
  }
  pageEvent(e:any){
    if(e<=0) return
    if(e>this.numarr.length) return
    this.page = e
    this.getSiteDiaryList()
  }
  getList(type:any) {
    this.action = type
    this.page = 1
    this.first = true
    this.getSiteDiaryList()
  }
  getstateName(name:string) {
    switch (name){
      case 'pm_signed':
        name = 'siow_signed'
        break
      case 'rejected':
        name = 'Recheck'
        break
    }
    return name
  }
  selectEvent(e:any){
    this.numarr = []
    this.page_size = e
    this.page = 1
    if(e==5){
    jQuery('.titleclss').css('height',`350px`)
    }else{
      jQuery('.titleclss').css('height',`${57*e}px`)
    }
    this.getSiteDiaryList()
  }

  //监听父组件传递过来的值
  ngOnChanges(changes:{[propKey:string]: SimpleChange }){
    console.log(changes)
    if(changes['displayTemplate']){
      if(changes['currentPage']['currentValue']!=1){
        this.page = changes['currentPage']['currentValue']
        this.action = changes['oldAction']['currentValue']
        this.first = true
       }
    }
  }

  getSiteDiaryList(){
    this.service.getSiteDiaryList(this.page,this.page_size, this.action).subscribe((res:any)=>{
      this.SiteDiaryList = res.list
      this.total_num = res.count
      this.canNoClick = !res.create_power
      this.numarr = []
      if(this.first){
        this.initPage()
      }
      for(let i=1;i<=Math.ceil(this.total_num/this.page_size);i++){
        this.numarr.push(i)
        this.numarr =Array.from(new Set(this.numarr))
      }
    })
  }

  // 右上角ADD按钮事件
  addSiteDiaryList(value:any, type: any){

    if(this.canNoClick ){
      return
    }
    jQuery('#top-menu').css('z-index','unset')
    if(value){
      window.history.replaceState(null, '', `${window.location.origin}${window.location.pathname}?route=edit&siteDiary=${value}`)
      this.listItemButton.emit('')
    }
    if(type === 'create' || type === 'edit') {
      this.topopup = !this.topopup
    } else if(type === 'copy') {

      this.toCopyPopup = !this.toCopyPopup
    }
  }

  //列表的三个按钮点击事件 edit approval pdf
  listItemClick(value:any,id:any){
    console.log(value,id)
    if(value==='edit'){
      window.history.replaceState(null, '', `${window.location.origin}${window.location.pathname}?route=edit&siteDiary=${id}&currentPage=${this.page}&action=${this.action}`)
      this.listItemButton.emit('')
    }else if(value==='view'){
      this.approval = !this.approval
      this.itemSiteDiary = id
    jQuery('#top-menu').css('z-index','unset')

    }else if(value==='delete'){
      this.service.deleteSiteDiaryList({site_diary_id:id.id}).subscribe((res:any)=>{
        this.numarr = []
        this.getSiteDiaryList()
      })
    } else if (value === 'copy') {

      this.copyId = id
      this.toCopyPopup = !this.toCopyPopup
    }
  }

  approvalClose(data:any){
    this.approval = !this.approval
    this.getSiteDiaryList()
    this.itemSiteDiary = null
  }

  //導出pdf
 getPdfHref(e:any, filename:any,pdf:string){
    this.showLoading = true
    let ele:any = this
    let project_id = jQuery('meta[name=current_project]').data('projectId')
    this.http.get(`/projects/${project_id}/site_diary_export/${e}?show_format=${pdf}`, {
      responseType: 'blob'
    }).subscribe ( (res:any) => {
      this.isShowPdfIndex = null
      var file = new File([res], filename, { type: 'application/force-download' });
      let url = URL.createObjectURL(file)
      let a = document.createElement('a')
      document.body.appendChild(a)
      a.download = filename + '.pdf'
      a.href = url
      a.click()
      a.remove()
      this.showLoading = false
    },
    (err:any) => {
        alert(this.i18n.t("js.check-lists.pdf.error"))
    })


  }
  //展示pdf下載項
  showPdfList(item:any){
    if(this.isShowPdfIndex == item){
      this.isShowPdfIndex = null
    }else{
      this.isShowPdfIndex = item
    }
  }
  //打开下载弹框
  openDownLoadDialog(){
    let self = this
    layui.use(['jquery','layer','laydate', ], function() {
      let date1 = new Date().toLocaleDateString().replace(/\//g,'-');
      let data2 = ExpressionService.lastMonth()
      let startTime = '',endTime='';
      var layer = layui.layer,$ = layui.$,
      laydate =  layui.laydate;
      layer.open({
        content:'<div class="layui-form"><div class="layui-form-item"><div class="layui-inline"><label class="layui-form-label" style="width: 100px;">Period</label><div class="layui-inline" id="test6"><div class="layui-input-inline"><input type="text" autocomplete="off" id="test-startDate-1" class="layui-input" placeholder="Form"></div><div class="layui-form-mid">-</div><div class="layui-input-inline"><input type="text" autocomplete="off" id="test-endDate-1" class="layui-input" placeholder="To"></div></div></div></div></div>',
        // area: ['400px', '400px'],
        title:'Export',
        btn:['Confirm'],
        yes: function(index:any, layero:any){
          let projectId:any = document.querySelector('meta[name=current_project]')
          let thisYearFrom = new Date().getFullYear() + '-' + '01-01'+ '-'
          let thisYearTo = new Date().getFullYear() + '-' + '12-31'
          window.open(`/projects/${projectId.dataset.projectId}/site_diary_export_excel?from=${startTime?startTime:thisYearFrom}&to=${endTime?endTime:thisYearTo}`)
          layer.close(index); //如果设定了yes回调，需进行手工关闭
        }
      });
      laydate.render({
        elem: '#test6',
        range: '-'
        ,format: 'yyyy-MM-dd',
        value: `${data2} - ${date1}`
        ,isInitValue: true
        ,lang: 'en'
        ,done: function(value:any, date:any, endDate:any){
          console.log(date,endDate); //得到日期生成的值，如：2017-08-18
          startTime = date.year+'-'+date.month+'-'+date.date
          endTime = endDate.year+'-'+endDate.month+'-'+endDate.date
          $('#test-startDate-1').val(startTime)
          $('#test-endDate-1').val(endTime)
        }
      });
  })

  }
  getConsultantList(){
    const currentProject: any = document.querySelector('meta[name=current_project]');
    const currentUser: any = document.querySelector('meta[name=current_user]');
    const id = currentProject.dataset.projectId
    this.http.get(`api/v3/projects/${ id }/available_assignees?role=Consultant`).subscribe((res: any) => {
        let UserData = res._embedded.elements.filter( (item:any) => {
          return item._type == 'User'
        })
        let type = UserData.find((item:any) =>item.id == currentUser.dataset.id)
        this.ConsultantType = type!=undefined? true :false
  })
  }
  initPage(){
    let self = this
    layui.use(['jquery','laypage', 'layer'], function(){
      var laypage = layui.laypage
      ,layer = layui.layer,$ = layui.$;
      laypage.render({
        elem: 'pagging',
        lang: 'en',
        limit:20,
        count: self.total_num,
        curr:self.page,
        layout: ['count', 'prev', 'page', 'next', 'limit',],
        jump: function(obj:any, first:any){
          $('.layui-laypage-limits').css({'width':'90px'})
          $('.layui-laypage-count').text('Total: '+obj.count)
           let arr = $('.layui-laypage-limits').children('select').children()
           for (let index = 0; index < arr.length; index++) {
              $(arr[index]).text('Per Page:'+ obj.limits[index])
           }
          if(!self.first){
            self.page_size = obj.limit
            self.page = obj.curr
            self.getSiteDiaryList()
          }
          if(first){
            self.first = false
          }
        }
      });
    })
  }
}
