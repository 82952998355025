import { ChangeDetectorRef, OnDestroy, Component, OnInit, Renderer2 } from "@angular/core";
import { I18nService } from "core-app/modules/common/i18n/i18n.service";
import { Title } from "@angular/platform-browser";
import { GridInitializationService } from "core-app/modules/grids/grid/initialization.service";
import { PathHelperService } from "core-app/modules/common/path-helper/path-helper.service";
import { GridResource } from "core-app/modules/hal/resources/grid-resource";
import { GridAddWidgetService } from "core-app/modules/grids/grid/add-widget.service";
import { GridAreaService } from "core-app/modules/grids/grid/area.service";
import { CurrentProjectService } from "core-components/projects/current-project.service";
import { GRID_PROVIDERS } from "core-app/modules/grids/grid/grid.component";
import { HttpClient } from "@angular/common/http";


//我的
import { EChartOption } from 'echarts';

declare let laydate: any;


@Component({
  templateUrl: "./overview.component.html",
  styleUrls: ["./overview.component.sass"],
  providers: GRID_PROVIDERS
})
export class OverviewComponent implements OnInit, OnDestroy {
  public projectList: any[] = [
    { id: 1, name: "Demo project", identifier: "demo-project" },
    { id: 2, name: "Scrum project", identifier: "your-scrum-project" },
    { id: 3, name: "Dev-empty", identifier: "dev-empty" },
    { id: 4, name: "Dev-large", identifier: "dev-large" },
    { id: 5, name: "Dev-large-child", identifier: "dev-large-child" },
    { id: 6, name: "Dev-custom-fields", identifier: "dev-custom-fields" }
  ];
  public pieColor =  ["#15c594", "#f0bf43", "#f05343"]
  //projectId
  public projectId: any;
  //projectIdentifier
  public projectIdentifier:any
  public workPageName:any;
  public showRow1:Array<String> = ['risc','risc(Survey)']
  public checkList:Array<String> = ['cleansing','tyding','weekly_env','weekly_safety']
  public showloading:boolean = false
//新增的柱状图是否需要展示
  public showRow2:Array<String> = ['risc(Survey)', 'risc', 'site_diary', 'cleansing', 'tyding', 'weekly_env', 'weekly_safety']
  //是否展示饼图
  public showPie:Array<String> =['risc(Survey)', 'risc']
  //用户
  public user:any = {
    name:'',
    company:'',
    position:'',
    lastName:'asd',
    firstName:'123'
  }
  // 饼图 配置
  public itemOption: object;
  //completed&incomplet饼图配置
  public pieItemOption: object;
  // 饼图 数据列表
  private itemList: any;
  private pieItemList: any;
  // 饼图 更新
  public updateItemOptions: any;
  // 新饼图 更新
  public updateItemOptionsNew: any;

  public LineOption:any;
  //new的柱状图配置
  public newLineOption:any;

  public updateLineOptions: any;
  // 柱状图 配置
  public axisOption: object;
  // 柱状图 数据列表
  private axisList: object;
  // 柱状图 更新
  public updateAxisOptions: any;
  //任务数据
  public dateEntry:any = {
    total: 0,
    new: 0,
    counter_signed: 0,
    closed: 0,
    pending: 0,
    processing: 0,
    finished: 0,
    risc_all: {
      total: 0,
      list: {}
    },
    risc_passed: {
      total: 0,
      list: {}
    },
    risc_failed: {
      total: 0,
      list: {}
    },
    risc_blank: {
      total: 0,
      list: {}
    },
    risc_late_submission:{
      total:0,
      list: {}
    },
    risc_form_submitted:{
      total:0,
      list: {}
    },
    risc_form_inspected:{
    total:0,
    list: {}
    },
    risc_form_endorsed: {
      total: 0,
      list: {}
    },
    risc_form_n_a: {
      total: 0,
      list: {}
    }

  }
  //季度数据
  public quarter:any=[
    {
      month:'01',
      quarterName:'Q1',
      name:"Jan&nbsp;&nbsp;&nbsp;&nbsp;Feb&nbsp;&nbsp;&nbsp;&nbsp;Mar"
    },
    {
      month:'04',
      quarterName:'Q2',
      name:"Apr&nbsp;&nbsp;&nbsp;&nbsp;May&nbsp;&nbsp;&nbsp;&nbsp;Jun"
    },
    {
      month:'07',
      quarterName:'Q3',
      name:"Jul&nbsp;&nbsp;&nbsp;&nbsp;Aug&nbsp;&nbsp;&nbsp;&nbsp;Sep"
    },
    {
      month:'10',
      quarterName:'Q4',
      name:"Oct&nbsp;&nbsp;&nbsp;&nbsp;Nov&nbsp;&nbsp;&nbsp;&nbsp;Dec"
    },
  ]
  //选择时间
  public dateBefore: any;
  public dateNow: any ;
  public text:any = 'risc'
  public graphicalTitle:any = 'RISC'
  public showTable:any = false
  public monthNow:any
  public monthBefore: any;
  public labour = ['v17','v03']
  public labourData:any;
  public riscChartPie:object;
  public riscBrokenLine:object;
  public riscChartColumnOne:object;
  public riscChartColumnTwo:object;
  public riscChartColumnThree:object;
  public riscChartPieData={name:'',valueList:[]as any[],color:[]as any[],valueKey:[] as any[]};
  public riscBrokenLineData:any;
  public riscChartColumnOneData:any;
  public riscChartColumnTwoData:any;
  public riscChartColumnThreeData:any;
  public quarterTime:any;//季度时间
  public quarterName:any;
  public FullYear:any;
  public showTimeFalg:boolean = false;
  public titleName:any={
      tableNamOne:'',
      tableNamTwo:'',
      tableNamThree:'',
      tableNamFour:'',
      tableNamFive:'',
  };
  public showTableone:any = ['newRisc','Safety Inspection']
  public showTabletwo:any = ['newRisc','Site Diary','Safety Inspection']
  public newCheckList:any = ['newRisc','Site Diary','Safety Inspection','newCleansing','Labour Return']
  public tapList:any = []
  constructor(
    readonly gridInitialization: GridInitializationService,
    // not used in the base class but will be used throughout the subclasses
    readonly pathHelper: PathHelperService,
    readonly currentProject: CurrentProjectService,
    readonly i18n: I18nService,
    readonly cdRef: ChangeDetectorRef,
    readonly title: Title,
    readonly addWidget: GridAddWidgetService,
    readonly renderer: Renderer2,
    readonly areas: GridAreaService,
    readonly http: HttpClient,
  ) { }

  ngOnInit(): void {
    laydate.render({
      elem: '#MonthCheckBorder',
      type:'month',
      lang: 'en',
      value: this.monthNow,
      range: ['#monthstartTime', '#monthendTime'],
      done:(value:any, date:any, endDate:any)=>{
        console.log(date,endDate);
        let endTab = ''
        let dateTab = ''
        if(endDate.month < 10) {
          endTab = '0'
        }
        if(date.month < 10) {
          dateTab = '0'
        }
        this.monthNow = endDate.year + '-' + endTab + endDate.month
        this.monthBefore = date.year + '-' + dateTab + date.month
        this.overviewWorkPage(this.workPageName);
        this.axisDataHandling();
        // if(this.judgeDate()>0){
        //   alert(this.i18n.t("js.projects_overview.overview.timeTips"))
        //   this.dateNow = this.dateBefore
        // }
      }
    });
    laydate.render({
      elem: '#DateCheckBorder',
      type:'date',
      value: this.dateNow,
      lang: 'en',
      range: ['#startTime', '#endTime'],
      done:(value:any, date:any, endDate:any)=>{
        console.log(date,endDate);
        this.dateNow = endDate.year + '-' + endDate.month + '-' + endDate.date
        this.dateBefore = date.year + '-' + date.month + '-' + date.date
        this.overviewWorkPage(this.workPageName);
        this.axisDataHandling();
        // if(this.judgeDate()>0){
        //   alert(this.i18n.t("js.projects_overview.overview.timeTips"))
        //   this.dateNow = this.dateBefore
        // }
      }
    });
    // console.log(this.getCurrentMonthFirst(), this.getCurrentMonthLast())
    var Date8 = new Date();
    var Date1 = new Date(Date8.getTime() - 30*24*60*60*1000);
    this.dateBefore = this.getDateTime(Date1)
    this.dateNow = this.getDateTime(Date8)
    this.monthBefore = this.getMonthTime(Date1)
    this.monthNow = this.getMonthTime(Date8)
    this.FullYear = Date8.getFullYear()
    this.checkDate( this.FullYear,Date8.getMonth())
    // console.log(this.quarterTime,Date8.getFullYear());

    // 最外层边距
    jQuery('#content-wrapper').css('padding', '0 0')
    const currentProject: any = document.querySelector('meta[name=current_project]');
    const currentUser: any = document.querySelector('meta[name=current_user]');
    this.projectId = currentProject.dataset.projectId;
    this.projectIdentifier = currentProject.dataset.projectIdentifier
    // this.allTableApi()
    // this.userid = currentUser.dataset.id
    // this.itemDataHandling();
    this.axisDataHandling();
    this.chartInit();
    this.overviewWorkPage('risc')
    this.http.get(`/api/v3/users/${currentUser.dataset.id}`).subscribe(res => {
      this.user = res
    });
  }

  username(){
    return `${this.user.lastName} ${this.user.firstName}`
  }
  ObjectEntries(data:any) {

    if(data.deficiencies_type_data) {

      return Object.entries(data.deficiencies_type_data)
    } else {

      return []
    }
  }
  // overview里面切换按钮
  ngAfterViewChecked() {
    //点击添加选中样式
    const that = this
    jQuery('.searchButlist').find("button").on('click', function () {
      if(jQuery(this)[0].dataset.typedata != that.text){
        console.log(jQuery(this)[0].dataset.typedata)
        that.graphicalTitle = jQuery(this)[0].innerText
        if(jQuery(this)[0].dataset.typedata){
          if(that.newCheckList.includes(jQuery(this)[0].dataset.typedata)){
            that.showTable = true
            that.showloading = true
            if(that.tapList.includes(false)){
              return
           }
            console.log(that.tapList);
          jQuery(this).addClass('adds').siblings().removeClass('adds')
            that.text = jQuery(this)[0].dataset.typedata
            that.allTableApi()
          }else {
          that.text = jQuery(this)[0].dataset.typedata
            that.showTable = false
            that.overviewWorkPage(jQuery(this)[0].dataset.typedata)
            that.axisDataHandling()
          jQuery(this).addClass('adds').siblings().removeClass('adds')

          }
        }
      }
    })

  }

  ngOnDestroy(): void {

  }

  getDateTime(date:any){
    var month: any = parseInt(date.getMonth() + 1);
    var day = date.getDate();
    if (month < 10) {
      month = '0' + month
    }
    if (day < 10) {
      day = '0' + day
    }
    return date.getFullYear() + '-' + month + '-' + day;
  }
  getMonthTime(date:any){
    var month: any = parseInt(date.getMonth() + 1);

    if (month < 10) {
      month = '0' + month
    }
    return date.getFullYear() + '-' + month;
  }

  getCurrentMonthFirst() {
    var date: any = new Date();
    date.setDate(1);
    var month: any = parseInt(date.getMonth() + 1);
    var day = date.getDate();
    if (month < 10) {
      month = '0' + month
    }
    if (day < 10) {
      day = '0' + day
    }
    return date.getFullYear() + '-' + month + '-' + day;
  }
  getCurrentMonthLast() {
    var date = new Date();
    var currentMonth = date.getMonth();
    var nextMonth = ++currentMonth;
    var nextMonthFirstDay: any = new Date(date.getFullYear(), nextMonth, 1);
    var oneDay = 1000 * 60 * 60 * 24;
    var lastTime: any = new Date(nextMonthFirstDay - oneDay);
    var month: any = parseInt(lastTime.getMonth() + 1);
    var day = lastTime.getDate();
    if (month < 10) {
      month = '0' + month
    }
    if (day < 10) {
      day = '0' + day
    }
    return date.getFullYear() + '-' + month + '-' + day;
  }

  // 饼图自适应
  public axisResponse() {
    if (document.body.offsetWidth > 1366) {
      this.updateItemOptions = {
        series: [
          {
            radius: ["56%", "42%"],
            label: {
              rich: {
                fontSize1: {
                  fontSize: 14,
                  lineHeight: 16
                },
                fontSize2: {
                  fontSize: 12,
                  lineHeight: 16
                }
              }
            },
            labelLine: {
              length: 48,
              length2: 36
            }
          }
        ]
      };
    } else if (document.body.offsetWidth <= 1366 && document.body.offsetWidth > 1024) {
      this.updateItemOptions = {
        series: [
          {
            radius: ["44%", "32%"],
            label: {
              rich: {
                fontSize1: {
                  fontSize: 14,
                  lineHeight: 20
                },
                fontSize2: {
                  fontSize: 12,
                  lineHeight: 16
                }
              }
            },
            labelLine: {
              length: 36,
              length2: 32
            }
          }
        ]
      };
    } else if (document.body.offsetWidth <= 1024 && document.body.offsetWidth > 768) {
      this.updateItemOptions = {
        series: [
          {
            radius: ["44%", "32%"],
            label: {
              rich: {
                fontSize1: {
                  fontSize: 14,
                  lineHeight: 18
                },
                fontSize2: {
                  fontSize: 12,
                  lineHeight: 14
                }
              }
            },
            labelLine: {
              length: 28,
              length2: 24
            }
          }
        ]
      };
    } else if (document.body.offsetWidth <= 768 && document.body.offsetWidth > 700) {
      this.updateItemOptions = {
        series: [
          {
            radius: ["56%", "42%"],
            label: {
              rich: {
                fontSize1: {
                  fontSize: 14,
                  lineHeight: 18
                },
                fontSize2: {
                  fontSize: 12,
                  lineHeight: 14
                }
              }
            },
            labelLine: {
              length: 60,
              length2: 48
            }
          }
        ]
      };
    } else if (document.body.offsetWidth <= 700 && document.body.offsetWidth > 480) {
      this.updateItemOptions = {
        series: [
          {
            radius: ["54%", "40%"],
            label: {
              rich: {
                fontSize1: {
                  fontSize: 14,
                  lineHeight: 18
                },
                fontSize2: {
                  fontSize: 12,
                  lineHeight: 14
                }
              }
            },
            labelLine: {
              length: 50,
              length2: 40
            }
          }
        ]
      };
    } else if (document.body.offsetWidth <= 480) {
      this.updateItemOptions = {
        series: [
          {
            radius: ["54%", "40%"],
            label: {
              rich: {
                fontSize1: {
                  fontSize: 12,
                  lineHeight: 18
                },
                fontSize2: {
                  fontSize: 12,
                  lineHeight: 14
                }
              }
            },
            labelLine: {
              length: 36,
              length2: 20
            }
          }
        ]
      };
    }
    this.updateItemOptionsNew = this.updateItemOptions
  }
  // 图标初始化
  public chartInit() {
    // 柱状图
    this.axisOption = {
      tooltip: {
          trigger: 'axis'
      },
      legend: {
          top: '4%',
          data: ['NEW', 'COUNTERSIGNED', 'CLOSED']
      },
      grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
      },
      xAxis: {
          type: 'category',
          boundaryGap: false,
      },
      yAxis: {
          type: 'value'
      },
      series: [
          {
              name: 'NEW',
              type: 'line',
          },
          {
              name: 'COUNTERSIGNED',
              type: 'line',
          },
          {
              name: 'CLOSED',
              type: 'line',
          }
      ]
  }
    // this.axisOption = {
    //   color: {
    //     type: "linear",
    //     x: 0,
    //     y: 0,
    //     x2: 0,
    //     y2: 1,
    //     colorStops: [
    //       {
    //         offset: 0,
    //         color: "#11b5b5" // 0% 处的颜色
    //       },
    //       {
    //         offset: 1,
    //         color: "#1bdfb8" // 100% 处的颜色
    //       }
    //     ],
    //     globalCoord: false
    //   },
    //   tooltip: {
    //     trigger: "axis",
    //     axisPointer: {
    //       type: "shadow"
    //     }
    //   },
    //   grid: {
    //     left: "5%",
    //     right: "5%",
    //     bottom: "3%",
    //     containLabel: true,
    //     width: "auto"
    //   },
    //   xAxis: [
    //     {
    //       type: "category",
    //       axisTick: {
    //         alignWithLabel: false
    //       }
    //     }
    //   ],
    //   yAxis: [
    //     {
    //       type: "value"
    //     }
    //   ],
    //   series: [
    //     {
    //       name: "Tasks",
    //       type: "bar",
    //       barWidth: "60%"
    //     }
    //   ]
    // };
    // 饼图
    console.log(this.pieColor)
    this.itemOption = {
      grid: {
        left: "5%",
        right: "5%",
        bottom: "3%",
        containLabel: true,
        width: "auto"
      },
      series: [
        {
          type: "pie",
          radius: ["50%", "30%"],
          legendHoverLink: false,
          hoverAnimation: false,
          hoverOffset: 0,
          label: {
            // show: false
            formatter: "{fontSize1| {b}} \n {fontSize2| {d} %}",
            rich: {
              fontSize1: {
                color: "#393939",
                fontSize: 16,
                fontWeight: 600,
                lineHeight: 20,
                align: "center"
              },
              fontSize2: {
                color: "#393939",
                fontSize: 14,
                fontWeight: 600,
                lineHeight: 16,
                align: "center"
              }
            }
          },
          labelLine: {
          show: true,
          length: 36,
          length2: 20
          },
          color: this.pieColor,
          data: this.itemList
        }
      ]
    };
    //pieItemOption饼图
    this.pieItemOption = {
      grid: {
        left: "5%",
        right: "5%",
        bottom: "3%",
        containLabel: true,
        width: "auto"
      },
      series: [
        {
          type: "pie",
          radius: ["50%", "30%"],
          legendHoverLink: false,
          hoverAnimation: false,
          hoverOffset: 0,
          label: {
            // show: false
            formatter: "{fontSize1| {b}} \n {fontSize2| {d} %}",
            rich: {
              fontSize1: {
                color: "#393939",
                fontSize: 16,
                fontWeight: 600,
                lineHeight: 20,
                align: "center"
              },
              fontSize2: {
                color: "#393939",
                fontSize: 14,
                fontWeight: 600,
                lineHeight: 16,
                align: "center"
              }
            }
          },
          labelLine: {
          show: true,
          length: 36,
          length2: 20
          },
          color: this.pieColor,
          data: this.pieItemList
        }
      ]
    };

    this.axisResponse();
    const self = this;
    let overviewCon = document.querySelectorAll(".overview-con") as NodeListOf<HTMLElement>;
    let overviewCon0 = document.querySelectorAll(".overview-con0") as NodeListOf<HTMLElement>;
    console.log(overviewCon0)
    for (let i = 0; i < overviewCon.length; i++) {
      overviewCon[i].style.height = overviewCon[0].offsetWidth  + "px";
    }
    for (let i = 0; i < overviewCon0.length; i++) {
      overviewCon0[i].style.height = overviewCon0[0].offsetWidth  / 2 + "px";
    }

    window.onresize = function () {
      let overviewCon = document.querySelectorAll(".overview-con") as NodeListOf<HTMLElement>;
      if (overviewCon[0].offsetHeight !== overviewCon[0].offsetWidth) {
        for (let i = 0; i < overviewCon.length; i++) {
          overviewCon[i].style.height = overviewCon[0].offsetWidth + "px";
        }
        for (let i = 0; i < overviewCon0.length; i++) {
          overviewCon0[i].style.height = overviewCon[0].offsetWidth / 2 + "px";
        }
      }
      self.axisResponse();
    };
  }

  // // 饼图数据处理
  // public itemDataHandling() {
  //   this.http.get("/api/v3/projects/" + this.projectId + "/gantt_chart/overview").subscribe(res => {
  //     let obj: any = res;
  //     if (obj.result === "Success") {
  //       this.itemList = [
  //         { value: obj.data.working, name: "Working" },
  //         { value: obj.data.expiring, name: "Expiring" },
  //         { value: obj.data.outdate, name: "Outdate" }
  //       ];
  //       this.updateItemOptions = {
  //         series: [
  //           {
  //             data: this.itemList
  //           }
  //         ]
  //       };
  //     }
  //   });
  // }
  // 柱状图数据处理
  public axisDataHandling() {
    let risc_type=this.workPageName == 'risc'?'work':'survey'
    let data = `?from=${this.dateBefore}&to=${this.dateNow}&risc_type=${risc_type}`
    this.http.get(`/api/v3/projects/${this.projectId}/overviews/risc_analysis_of_status_within_days${data}`).subscribe(res => {
      console.log(res)
      let obj: any = res;

      // if (obj.result === "Success") {
      //   // this.axisList = {
      //   //   date: Object.keys(res.data),
      //   //   tasks: Object.values(res.data)
      //   // };
        this.updateAxisOptions = {
          xAxis: [
            {
              data: Object.keys(obj.new)
            }
          ],
          series: [
            {
              data: Object.values(obj.new)
            },
            {
              data: Object.values(obj.counter_signed)
            },
            {
              data: Object.values(obj.closed)
            }
          ]
        };
      // }
    });
  }
  public overviewRiscLine (data:any) {
    console.log(data,'数据');
    this.LineOption = {
      tooltip: {
          trigger: 'axis',
          axisPointer: {
              type: 'cross',
              label: {
                  backgroundColor: '#6a7985'
              }
          }
      },
      legend: {
          data: ['Accepted', 'Rejected', 'Progress', 'Not Ready']
      },
      toolbox: {
          feature: {
          }
      },
      grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
      },
      xAxis: [
          {
              type: 'category',
              boundaryGap: false,
              data: data.date
          }
      ],
      yAxis: [
          {
              type: 'value',
              axisLine: {
                show: false
              },
              axisTick: {
                show: false
              },
              splitLine: {
                show: false
              }
          }
      ],
      series: [
          {
              name: 'Accepted',
              type: 'line',
              stack: '总量',
              itemStyle: {
                color: '#72c594'
              },
              lineStyle: {
                width: 0,
                color: '#72c594'
              },
              areaStyle: {
                color: '#72c594'
              },
                      smooth: true,
              emphasis: {
                  focus: 'series'
              },
              data: data.passed
          },
          {
              name: 'Rejected',
              type: 'line',
              stack: '总量',
              smooth: true,
              itemStyle: {
                color: '#f17f5e'
              },
              lineStyle: {
                width: 0,
                color: '#f17f5e'
              },
              areaStyle: {
                color: '#f17f5e'
              },
              emphasis: {
                  focus: 'series'
              },
              data: data.failed
          },
          {
              name: 'Progress',
              type: 'line',
              stack: '总量',
              smooth: true,
              itemStyle: {
                color: '#fbc568'
              },
              lineStyle: {
                width: 0,
                color: '#fbc568'
              },
              areaStyle: {
                color: '#fbc568'
              },
              emphasis: {
                  focus: 'series'
              },
              data: data.blank
          },
          {
            name: 'Not Ready',
            type: 'line',
            stack: '总量',
            smooth: true,
            itemStyle: {
              color: '#3f48cc'
            },
            lineStyle: {
              width: 0,
              color: '#3f48cc'
            },
            areaStyle: {
              color: '#3f48cc'
            },
            emphasis: {
                focus: '#3f48cc'
            },
            data: data.notReady
        }
      ]
  };
  }

  public overviewNewRiscLine (data:any) {

    this.newLineOption = {
      tooltip: {
          trigger: 'axis',
          axisPointer: {
              type: 'cross',
              label: {
                  backgroundColor: '#6a7985'
              }
          }
      },
      legend: {
          data: ['Completed', 'Incompleted']
      },
      toolbox: {
          feature: {
          }
      },
      grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
      },
      xAxis: [
          {
              type: 'category',
              boundaryGap: false,
              data: data.dateList
          }
      ],
      yAxis: [
          {
              type: 'value',
              axisLine: {
                show: false
              },
              axisTick: {
                show: false
              },
              splitLine: {
                show: false
              }
          }
      ],
      series: [
          {
              name: 'Completed',
              type: 'line',
              stack: '总量',
              itemStyle: {
                color: '#72c594'
              },
              lineStyle: {
                width: 0,
                color: '#72c594'
              },
              areaStyle: {
                color: '#72c594'
              },
                      smooth: true,
              emphasis: {
                  focus: 'series'
              },
              data: data.complete
          },
          {
              name: 'Incompleted',
              type: 'line',
              stack: '总量',
              smooth: true,
              itemStyle: {
                color: '#f17f5e'
              },
              lineStyle: {
                width: 0,
                color: '#f17f5e'
              },
              areaStyle: {
                color: '#f17f5e'
              },
              emphasis: {
                  focus: 'series'
              },
              data: data.incomplete
          },
      ]
  };
  }

  // public overviewRiscLine (data:any) {
  //   this.LineOption = {
  //     tooltip: {
  //         trigger: 'axis',
  //         axisPointer: {
  //             type: 'cross',
  //             label: {
  //                 backgroundColor: '#6a7985'
  //             }
  //         }
  //     },
  //     legend: {
  //         data: ['Accepted', 'Rejected', 'Progress', 'Not Ready']
  //     },
  //     toolbox: {
  //         feature: {
  //         }
  //     },
  //     grid: {
  //         left: '3%',
  //         right: '4%',
  //         bottom: '3%',
  //         containLabel: true
  //     },
  //     xAxis: [
  //         {
  //             type: 'category',
  //             boundaryGap: false,
  //             data: data.date
  //         }
  //     ],
  //     yAxis: [
  //         {
  //             type: 'value',
  //             axisLine: {
  //               show: false
  //             },
  //             axisTick: {
  //               show: false
  //             },
  //             splitLine: {
  //               show: false
  //             }
  //         }
  //     ],
  //     series: [
  //         {
  //             name: 'Accepted',
  //             type: 'line',
  //             stack: '总量',
  //             itemStyle: {
  //               color: '#72c594'
  //             },
  //             lineStyle: {
  //               width: 0,
  //               color: '#72c594'
  //             },
  //             areaStyle: {
  //               color: '#72c594'
  //             },
  //                     smooth: true,
  //             emphasis: {
  //                 focus: 'series'
  //             },
  //             data: data.passed
  //         },
  //         {
  //             name: 'Rejected',
  //             type: 'line',
  //             stack: '总量',
  //             smooth: true,
  //             itemStyle: {
  //               color: '#f17f5e'
  //             },
  //             lineStyle: {
  //               width: 0,
  //               color: '#f17f5e'
  //             },
  //             areaStyle: {
  //               color: '#f17f5e'
  //             },
  //             emphasis: {
  //                 focus: 'series'
  //             },
  //             data: data.failed
  //         },
  //         {
  //             name: 'Progress',
  //             type: 'line',
  //             stack: '总量',
  //             smooth: true,
  //             itemStyle: {
  //               color: '#fbc568'
  //             },
  //             lineStyle: {
  //               width: 0,
  //               color: '#fbc568'
  //             },
  //             areaStyle: {
  //               color: '#fbc568'
  //             },
  //             emphasis: {
  //                 focus: 'series'
  //             },
  //             data: data.blank
  //         },
  //         {
  //           name: 'Not Ready',
  //           type: 'line',
  //           stack: '总量',
  //           smooth: true,
  //           itemStyle: {
  //             color: '#3f48cc'
  //           },
  //           lineStyle: {
  //             width: 0,
  //             color: '#3f48cc'
  //           },
  //           areaStyle: {
  //             color: '#3f48cc'
  //           },
  //           emphasis: {
  //               focus: '#3f48cc'
  //           },
  //           data: data.notReady
  //       }
  //     ]
  // };
  // }
  // 饼图数据处理
  public overviewWorkPage(workPageName:any) {

    console.log(this.judgeDate(),workPageName)
    this.workPageName = workPageName;
    let data
    console.log(this.dateBefore,this.dateNow)
    if(this.showRow1.includes(workPageName)) {
      let risc_type=this.workPageName == 'risc'?'work':'survey'
      data = `risc?from=${this.dateBefore}&to=${this.dateNow}&risc_type=${risc_type}`
    } else if(this.checkList.includes(workPageName)) {
        data = `checklist?from=${this.dateBefore}&to=${this.dateNow}&checklist_type=${workPageName}`
    } else if(workPageName == 'site_diary') {
      data = `${workPageName}?from=${this.dateBefore}&to=${this.dateNow}`
    } else if(workPageName == 'SEDN') {
      data = `sedn?from=${this.dateBefore}&to=${this.dateNow}`
    } else if(this.labour.includes(workPageName)) {
      data = `labour_return?from=${this.monthBefore}&to=${this.monthNow}&type=${workPageName}`
    }
    this.showloading = true
    this.http.get(`/api/v3/projects/${this.projectId}/overviews/${data}`).subscribe((res:any) => {
      this.showloading = false
      console.log(res)
      if(res) {
       this.labourData = Object.entries(res)
       console.log(this.labourData)
      }
      this.dateEntry = {...this.dateEntry,...res}
      console.log(this.dateEntry)
      let Linedata:any = {}
      if(this.showRow1.includes(this.text)) {
        this.pieColor =  ["#72c594", "#f17f5e", "#fbc568", "#3f48cc"]
        this.itemList = [
          {
            value: res.risc_passed.total,
            name: 'Accepted'
          },
            {value: res.risc_failed.total, name: 'Rejected'},
            {value: res.risc_blank.total, name: 'Progress'},
            {value: res.risc_not_ready.total, name: 'Not Ready'},
        ]
        Linedata.date = Object.keys(res.risc_all.list)
        Linedata.passed = Object.values(res.risc_passed.list)
        Linedata.failed = Object.values(res.risc_failed.list)
        Linedata.blank = Object.values(res.risc_blank.list)
        Linedata.notReady = Object.values(res.risc_not_ready.list)
        this.overviewRiscLine(Linedata)
      } else if(this.checkList.includes(this.text)) {
        this.pieColor =  ["#72c594", "#f17f5e", "#fbc568"]
        this.itemList = [
            {value: this.dateEntry.total_completed, name: 'Completed'},
            {value: this.dateEntry.total_incompleted, name: 'Incompleted'},

        ]
      } else if(this.text == 'site_diary'){
        this.pieColor =  ["#72c594", "#f17f5e", "#fbc568"]
        this.itemList = [
            {value: this.dateEntry.sd_completed, name: 'Completed'},
            {value: this.dateEntry.sd_incompleted, name: 'Incompleted'},

        ]
      } else if(this.text == 'SEDN') {
        this.pieColor =  ["#72c594","#fbc568", "rgb(63 ,72 ,204 )", "#f17f5e"]
        this.itemList = [
            {value: this.dateEntry.total_completed, name: 'Completed'},
            {value: this.dateEntry.total_sent, name: 'Sent'},
            {value: this.dateEntry.total_closed, name: 'Close'},
            {value: this.dateEntry.total_new, name: 'new'},

        ]
      } else {
        this.itemList = [
          { value: res.processing, name: "Processing" },
          { value: res.pending, name: "Pending" },
          { value: res.finished, name: "Finished" }
        ];
        this.pieColor =  ["#15c594", "#f0bf43", "#f05343"]
      }


      console.log(this.itemList, this.pieColor)
      this.updateItemOptions = {
        series: [
          { color: this.pieColor,
            data: this.itemList,
            labelLine: {
              show: true,
              length: 36,
              length2: 20
              },
          }
        ]
      };
    });
    if(this.showPie.includes(workPageName)){
      let risc_type= workPageName == 'risc'?'work':'survey'
      this.http.get(`/api/v3/projects/${this.projectId}/overviews/wsci?from=${this.dateBefore}&to=${this.dateNow}&risc_type=${risc_type}`).toPromise().then((res:any) => {
        let testcolor = ["#72c594","#f17f5e"]
        let textlist = [ {value: res.total_completed, name: 'completed'},
        {value: res.total_incompleted, name: 'incompleted'},]
        this.updateItemOptionsNew =  {
          series: [
            { color: testcolor,
              data: textlist,
              labelLine: {
                show: true,
                length: 36,
                length2: 20,
                },
            }
          ]
        };
      })
    }
      // this.returnNewApi(workPageName)
      // console.log(this.returnNewApi(workPageName),'wo yaos dkiddk');
      if(this.showRow2.includes(workPageName)) {
          this.http.get(`/api/v3/projects/${this.projectId}/overviews/${this.returnNewApi(workPageName)}`).toPromise().then((res:any) => {
            let lineData:any = {}
              if(res){
                console.log(res,res['complete'])
                lineData.dateList = Object.keys(res['completed'])

                lineData.complete = Object.values(res['completed'])

                lineData.incomplete = Object.values(res['incompleted'])

                this.overviewNewRiscLine(lineData)
              }
      });
    }
  }

  //返回newoverviewNewRiscLine掉那个结果
  returnNewApi(workPageName:string){

    let newData
    console.log(this.dateBefore,this.dateNow)
    if(workPageName == 'risc(Survey)'|| workPageName == 'risc') {
      let risc_type= workPageName == 'risc'?'work':'survey'
      newData = `risc_analysis_of_status_within_days_wsci?from=${this.dateBefore}&to=${this.dateNow}&risc_type=${risc_type}`
    } else if(workPageName == 'site_diary') {
      newData = `site_diary_ci?from=${this.dateBefore}&to=${this.dateNow}`
    } else{
      console.log('进来的');

      newData= `checklist_diagram_ci?from=${this.dateBefore}&to=${this.dateNow}&risc_type=${workPageName}`
    }
    return newData
  }

  okTime(){
    this.overviewWorkPage(this.text)
    this.axisDataHandling()
  }


  judgeDate(){
    console.log(this.dateNow)
    return new Date(this.dateBefore).getTime()-new Date(this.dateNow).getTime();
  }

  //时间事件
  dateNowChange() {
    laydate.render({
      elem: '#DateCheckBorder',
      type:'date',
      value:this.dateNow,
      lang: 'en',
      range: ['#startTime', '#endTime'],
      show:true,
      done:(value:any)=>{
        this.dateNow = value
        if(this.judgeDate()>0){
          alert(this.i18n.t("js.projects_overview.overview.timeTips"))
          this.dateNow = this.dateBefore
        }
      }
    });
  }

  dateBeforeChange(){
    laydate.render({
      elem: '#dateBefore',
      type:'date',
      lang: 'en',
      value:this.dateBefore,
      show:true,
      done:(value:any)=>{
        this.dateBefore = value
        if(this.judgeDate()>0){
          alert(this.i18n.t("js.projects_overview.overview.timeTips"))
          this.dateNow = this.dateBefore
        }
      }
    });
  }

  imgdata(name:any){

    return `${window.location.origin}/images/dashboard/${name}`
  }
  allTableApi(){

    switch (this.text) {
      case 'newRisc':
        this.titleName.tableNamOne = 'Top 4 Distribution of NC RISC'
        this.titleName.tableNamTwo = 'Trend of Top 4 NC RISC'
        this.titleName.tableNamThree = 'Top 4 Failure Rate of 1st Inspection of RISC'
        this.titleName.tableNamFour = 'Top 4 Outstanding RISC'
          this.getRiscOne('risc_one')
          this.getRiscTwo('risc_two')
          this.getRiscThree('risc_three','',true)
          this.getRiscFour('risc_four')
        break;
      case 'Site Diary':
        this.titleName.tableNamOne = 'Distribution of Overdue Site Diary'
        this.titleName.tableNamThree = 'Overdue Site Diary'
        this.titleName.tableNamFour = 'Completed/ In-Complete/ Outstanding Site Diary Statistics'
        this.getRiscOne('site_diary_one')
        this.getRiscThree('site_diary_two')
        this.getRiscFour('site_diary_three','',true)
      break;
      case 'Safety Inspection':
        this.titleName.tableNamFive = 'NC / Near Miss Reports of Site Safety Inspection Statistics'
        this.titleName.tableNamTwo = 'Trend of Non Compliance'
        this.titleName.tableNamThree = 'Completed/ In-Completed/ Outstanding'
        this.titleName.tableNamFour = 'Accident Rate / 100,000 Man-hours'
        this.getRiscFive('safety_one')
        this.getRiscTwo('safety_one')
        this.getRiscThree('safety_three','',true)
        this.getRiscFour('safety_four','',true)
      break;
      case 'newCleansing':
        this.titleName.tableNamThree = 'Completed/ In-Completed/ Outstanding of Cleansing Inspection Record Statistics'
        this.titleName.tableNamFour = 'NC Cleansing Inspection Records'
        this.getRiscThree('cleansing_one','cic_1',true)
        this.getRiscFour('cleansing_one','cic_2')
      break;
      case 'Labour Return':
        this.titleName.tableNamThree = 'Top 4 Increase in Wages (in %) by Labour Type'
        this.titleName.tableNamFour = 'Top 4 Labour Deployment by Labour Type'
        this.getRiscThree('labour_one','lr_1',true)
        this.getRiscFour('labour_one','lr_2')
      break;

      default:
        break;
    }
    // this.getRiscOne()
    // this.getRiscTwo()
    // this.getRiscThree()
    // this.getRiscFour()
  }
  initTable(key:any,islabel?:boolean){
    // 饼图初始化

    switch (key) {
      case 0:
        this.riscChartPie ={
          grid: {
            left: "5%",
            right: "5%",
            bottom: "3%",
            containLabel: true,
            width: "auto"
          },
          legend: {
            show:true,
            bottom: 10,
            left: 'center',
            data: this.riscChartPieData.valueKey
          },
          series: [
            {
              type: "pie",
              radius: "50%",
              hoverOffset: 0,
              label: {
                // show: false
                formatter: "{fontSize1| {b}} \n {fontSize2| {d} %}",
                rich: {
                  fontSize1: {
                    color: "#393939",
                    fontSize: 16,
                    fontWeight: 600,
                    lineHeight: 20,
                    align: "center"
                  },
                  fontSize2: {
                    color: "#393939",
                    fontSize: 14,
                    fontWeight: 600,
                    lineHeight: 16,
                    align: "center"
                  }
                }
              },
              labelLine: {
              show: true,
              length: 36,
              length2: 20
              },
              color: this.riscChartPieData.color,
              data: this.riscChartPieData.valueList,
            }
          ]
        }
        break;
      case 1:
        this.riscBrokenLine = {
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            show:true,
            left: 'center',
            data: this.riscBrokenLineData.valueKey
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: this.riscBrokenLineData.xdata
          },
          yAxis: {
            type: 'value',
            axisLine: {
              show: false, //隐藏y轴
            },
          },
          series: this.riscBrokenLineData.valueList
        };
        break;
      case 2:
        this.riscChartColumnOne = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
              label: {
                show: true
              }
            },
            formatter: function (params:any) {
              var html = '';
              if (params.length != 0) {
                // 可以自己打印一下console.info(params[0]);
                var getName = params[0].name;
                html += getName + '<br/>';
                for (var i = 0; i < params.length; i++) {
                  // 如果为0 为空的数据我们不要了(你们可以直接判断 > 0)
                  if (
                    params[i].value != null
                    &&
                    // params[i].value != 0 &&
                    params[i].value != ''
                  ) {
                    html += params[i].marker;
                    html += params[i].seriesName + ': ' + params[i].value + '<br/>';
                  }
                }
              }
              return html;
            }
          },
          calculable: true,
          legend: {
            bottom: 0,
            left: 'center',
          },
          grid: {
            top: '12%',
            left: '1%',
            right: '10%',
            containLabel: true
          },
          xAxis: this.riscChartColumnOneData.xdata,
          yAxis: {
            min:islabel == undefined ?undefined:0,   //最小百分比
            // max:islabel == undefined ?undefined:100, //最大百分比
            axisLine: {
            show: false, //隐藏y轴
          },
          axisLabel: {
            show: true,
            interval: 0, // 使x轴文字显示全
            color: '#666666',
            formatter: islabel?'{value}%':'{value}'  //y轴数值，带百分号
          },
        },
          series: this.riscChartColumnOneData.valueList
        };
        break;
      case 3:
        this.riscChartColumnTwo = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
              label: {
                show: true
              }
            },
            formatter: function (params:any) {
              var html = '';

              if (params.length != 0) {
                // 可以自己打印一下console.info(params[0]);
                var getName = params[0].name;
                html += getName + '<br/>';
                for (var i = 0; i < params.length; i++) {
                  // 如果为0 为空的数据我们不要了(你们可以直接判断 > 0)
                  if (
                    params[i].value != null
                    &&
                    // params[i].value != 0 &&
                    params[i].value != ''
                  ) {
                    html += params[i].marker;
                    html += params[i].seriesName + ': ' + params[i].value + '<br/>';
                  }
                }
              }
              return html;
            }
          },
          calculable: true,
          legend: {
            bottom: 10,
            left: 'center',
          },
          grid: {
            top: '12%',
            left: '1%',
            right: '10%',
            containLabel: true
          },
          xAxis: this.riscChartColumnTwoData.xdata,
          yAxis: {
            min:islabel == undefined ?undefined:0,   //最小百分比
            // max:islabel == undefined ?undefined:100,
            axisLine: {
            show: false, //隐藏y轴
          },
          axisLabel: {
            show: true,
            interval: 0, // 使x轴文字显示全
            color: '#666666',
            formatter: islabel?'{value}%':'{value}'  //y轴数值，带百分号
          },
        },
          series: this.riscChartColumnTwoData.valueList
        };
        break;
      case 4:
        this.riscChartColumnThree = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
              label: {
                show: true
              }
            },
            formatter: function (params:any) {
              var html = '';

              if (params.length != 0) {
                // 可以自己打印一下console.info(params[0]);
                var getName = params[0].name;
                html += getName + '<br/>';
                for (var i = 0; i < params.length; i++) {
                  // 如果为0 为空的数据我们不要了(你们可以直接判断 > 0)
                  if (
                    params[i].value != null
                    &&
                    // params[i].value != 0 &&
                    params[i].value != ''
                  ) {
                    html += params[i].marker;
                    html += params[i].seriesName + ': ' + params[i].value + '<br/>';
                  }
                }
              }
              return html;
            }
          },
          calculable: true,
          legend: {
            bottom: 10,
            left: 'center',
          },
          grid: {
            top: '12%',
            left: '1%',
            right: '10%',
            containLabel: true
          },
          xAxis: this.riscChartColumnThreeData.xdata,
          yAxis: {   axisLine: {
            show: false, //隐藏y轴
          },},
          series: this.riscChartColumnThreeData.valueList
        };
        break;
      default:
        break;
    }
  }
  // risc_1接口
  getRiscOne(param:any){
    this.tapList.splice(0, 0, false);
    this.riscChartPieData = {name:'',valueList:[]as any[],color:[]as any[],valueKey:[] as any[]};
    let data = {
      name:'',
      color:["#00afd3","#f3871e","#81bb00","#fbdf2c"],
      valueList:[] as any[],
      valueKey:[] as any[]
    }
    this.http.get(`/api/v3/projects/${this.projectId}/overviews/${param}?to=${this.quarterTime}`).toPromise().then((res:any) => {
      data.name = Object.keys(res)[0];
        for (const key in res[Object.keys(res)[0]]) {
          if (Object.prototype.hasOwnProperty.call(res[Object.keys(res)[0]], key)) {
            let obj = {value:res[Object.keys(res)[0]][key],name:key}
            data.valueList.push(obj)
            data.valueKey.push(key)
          }
        }
      this.riscChartPieData = data
      this.tapList.splice(this.tapList.length-1, 1);
      if(this.tapList.length==0){
        this.showloading = false
      }
    console.log(this.tapList,0);

      this.initTable(0)
    })
  }
  // risc_2接口
  getRiscTwo(param:any){
   this.tapList.splice(1, 0, false);
   this.riscBrokenLineData = {}
   this.riscBrokenLineData = {
      valueList:[] as any[],
      valueKey:[] as any[]
    }
    this.http.get(`/api/v3/projects/${this.projectId}/overviews/${param}?to=${this.quarterTime}`).toPromise().then((res:any) => {
      this.riscBrokenLineData.valueKey = Object.keys(res[Object.keys(res)[0]])
      this.riscBrokenLineData.xdata = Object.keys(res).reverse()
      for (let index = 0; index < this.riscBrokenLineData.valueKey.length; index++) {
          this.riscBrokenLineData.valueList.push({ name: this.riscBrokenLineData.valueKey[index],
            type: 'line',
            data: []})
      }
        for (let keyIndex = 0; keyIndex < this.riscBrokenLineData.xdata.length; keyIndex++) {
            for (const key1 in res[this.riscBrokenLineData.xdata[keyIndex]]) {
              if (Object.prototype.hasOwnProperty.call(res[this.riscBrokenLineData.xdata[keyIndex]], key1)) {
                    for (let index = 0; index <  this.riscBrokenLineData.valueList.length; index++) {
                      if(this.riscBrokenLineData.valueList[index].name == key1){
                        this.riscBrokenLineData.valueList[index].data.push(res[this.riscBrokenLineData.xdata[keyIndex]][key1])
                      }
                    }
              }
          }
        }
      this.initTable(1)
      this.tapList.splice(this.tapList.length-1,1);
      if(this.tapList.length==0){
        this.showloading = false
      }
    console.log(this.tapList,1);

    })
  }
  getRiscThree(param:any, type?: string,islabel?:boolean){
   this.tapList.splice(2, 0, false);
    this.riscChartColumnOneData = {}
    this.riscChartColumnOneData = {
      valueList:[] as any[],
      valueKey:[] as any[],
      legendList:[] as any[],
      xdata:[] as any[],
    }
    this.http.get(`/api/v3/projects/${this.projectId}/overviews/${param}?to=${this.quarterTime}`).toPromise().then((ress:any) => {
      var res
      if(type){
          res = ress[type]
        }else{
          res = ress
        }
        this.riscChartColumnOneData.legendList = Object.keys(res).reverse()
        for (let index = 0; index <  this.riscChartColumnOneData.legendList.length; index++) {
              let xdata = {
                type: 'category',
                position: 'bottom',
                data: [] as any[]
              }
            for (let index2 = 0; index2 < this.riscChartColumnOneData.legendList.length; index2++) {
              if(index==index2){
                xdata.data.push(this.riscChartColumnOneData.legendList[index])
              }else{
                xdata.data.push('')
              }
            }
            this.riscChartColumnOneData.xdata.push(xdata)

          for (const key in res[this.riscChartColumnOneData.legendList[index]]) {
            let data = []
            for (let index1 = 0; index1 <= index; index1++) {
              // const element = array[index];
              if(index==index1){
                data.push(String(res[this.riscChartColumnOneData.legendList[index]][key]))
              }else{
                data.push('')
              }
            }
              this.riscChartColumnOneData.valueList.push({
                name: key,
                type: 'bar',
                xAxisIndex: index,
                data: data,
                barWidth: 25,
                itemStyle: {
                  color:key=="complete"||key=="completed"?'#1296db':key=="incomplete"?'#e91b25':key=="outstanding"?'#61a0a8':null
                }
              },)

          }

        }

      this.initTable(2,islabel)
      this.tapList.splice(this.tapList.length-1, 1);
      if(this.tapList.length == 0){
        this.showloading = false
      }
      console.log(this.tapList,2);
    })
  }
  getRiscFour(param:any, type?: string,islabel?:boolean){
   this.tapList.splice(3, 0, false);
    this.riscChartColumnTwoData = {}
    this.riscChartColumnTwoData = {
      valueList:[] as any[],
      valueKey:[] as any[],
      legendList:[] as any[],
      xdata:[] as any[],
    }
    this.http.get(`/api/v3/projects/${this.projectId}/overviews/${param}?to=${this.quarterTime}`).toPromise().then((ress:any) => {
      var res
      if(type){
          res = ress[type]
        }else{
          res = ress
        }

      this.riscChartColumnTwoData.legendList = Object.keys(res).reverse()
      for (let index = 0; index <  this.riscChartColumnTwoData.legendList.length; index++) {
            let xdata = {
              type: 'category',
              position: 'bottom',
              data: [] as any[]
            }
          for (let index2 = 0; index2 < this.riscChartColumnTwoData.legendList.length; index2++) {
            if(index==index2){
              xdata.data.push(this.riscChartColumnTwoData.legendList[index])
            }else{
              xdata.data.push('')
            }
          }
          this.riscChartColumnTwoData.xdata.push(xdata)

        for (const key in res[this.riscChartColumnTwoData.legendList[index]]) {
          let data = []
          for (let index1 = 0; index1 <= index; index1++) {
            // const element = array[index];
            if(index==index1){
              data.push(String(res[this.riscChartColumnTwoData.legendList[index]][key]))
            }else{
              data.push('')
            }
          }
            this.riscChartColumnTwoData.valueList.push({
              name: key,
              type: 'bar',
              xAxisIndex: index,
              data: data,
              barWidth: 25,
              itemStyle: {
                color:key=="complete"||key=="completed"?'#1296db':key=="incomplete"?'#e91b25':key=="outstanding"?'#61a0a8':null
              }
            },)
        }

      }
    console.log(this.riscChartColumnTwoData,this.tapList);
    this.initTable(3,islabel)
    this.tapList.splice(this.tapList.length-1, 1);
    if(this.tapList.length==0){
      this.showloading = false
    }
    console.log(this.tapList,3);

  })
  }
  getRiscFive(param:any){
   this.tapList.splice(4, 0, false);
    this.riscChartColumnThreeData = {}
    this.riscChartColumnThreeData = {
      valueList:[] as any[],
      valueKey:[] as any[],
      legendList:[] as any[],
      xdata:[] as any[],
    }
    this.http.get(`/api/v3/projects/${this.projectId}/overviews/${param}?to=${this.quarterTime}`).toPromise().then((res:any) => {
      this.riscChartColumnThreeData.legendList = Object.keys(res).reverse()
      for (let index = 0; index <  this.riscChartColumnThreeData.legendList.length; index++) {
            let xdata = {
              type: 'category',
              position: 'bottom',
              data: [] as any[]
            }
          for (let index2 = 0; index2 < this.riscChartColumnThreeData.legendList.length; index2++) {
            if(index==index2){
              xdata.data.push(this.riscChartColumnThreeData.legendList[index])
            }else{
              xdata.data.push('')
            }
          }
          this.riscChartColumnThreeData.xdata.push(xdata)

        for (const key in res[this.riscChartColumnThreeData.legendList[index]]) {
          let data = []
          for (let index1 = 0; index1 <= index; index1++) {
            // const element = array[index];
            if(index==index1){
              data.push(String(res[this.riscChartColumnThreeData.legendList[index]][key]))
            }else{
              data.push('')
            }
          }
            this.riscChartColumnThreeData.valueList.push({
              name: key,
              type: 'bar',
              xAxisIndex: index,
              data: data,
              barWidth: 25
            },)
        }

      }
    this.initTable(4)
    this.tapList.splice(this.tapList.length-1, 1);
    if(this.tapList.length==0){
      this.showloading = false
    }
    console.log(this.tapList,4);
  })
  }
  //选中时间
  checkDate(item:any,month:any){
    if(this.tapList.includes(false)){
      return
   }
    if(month=='view'){
      this.quarterName = this.FullYear+item.quarterName
      this.quarterTime = this.FullYear+'-'+item.month +'-'+'01'
    }else{
        if(month<=2){
          this.quarterName = this.FullYear+ 'Q1'
          this.quarterTime = this.FullYear+'-'+'01'+'-'+'01'
        }else if (month<=5){
          this.quarterName = this.FullYear+ 'Q2'
          this.quarterTime = this.FullYear+'-'+'04'+'-'+'01'

        }else if (month<=8){
          this.quarterName = this.FullYear+ 'Q3'
          this.quarterTime = this.FullYear+'-'+'07'+'-'+'01'

        }else {
          this.quarterName = this.FullYear+ 'Q4'
          this.quarterTime = this.FullYear+'-'+'10'+'-'+'01'
        }
    }
    this.showloading = true
    this.allTableApi()
    this.showTimeFalg = false
  }
  // 展示时间组件
  showDialog(){
    this.showTimeFalg = !this.showTimeFalg
  }
  changeYear(item:any){
    if(item=='add'){
      this.FullYear++
    }else{
      this.FullYear--
    }
  }

  exportExcel(){
    let type = this.text=== 'risc'? 'work' : 'survey'
    window.open(`/projects/${this.projectId}/non_c_excel/?risc_type=${type}&from=${this.dateBefore}&to=${this.dateNow}`)
  }
}
